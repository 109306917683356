(function ($) {
  Drupal.behaviors.porellMapBehavior = {
    attach: function(context, settings){
      $('#google-map', context).once('google-maps', function(){
        var address = "Handelsstraat 21 Oostkamp";
        setupMapWithAddress(address, 'google-map', 'large');
      });
    }
  };

  function setupMapWithAddress(address, mapId, iconSize) {
    getLatLong(address);
    var locations = [];
    function getLatLong(address) {
      var geo = new google.maps.Geocoder;
      geo.geocode({
        'address': address,
        region: 'no'
      }, function(results, status) {
        if (status.toLowerCase() == 'ok') {
             // locations[] = results[0].geometry.location;
             locations.push(results[0].geometry.location);
             initializeMap(locations, mapId, iconSize);
           } else {
            // alert("problem");
          }
        });
    }
  }

    function initializeMap(locations, mapId, iconSize) {
      var gmarkers = [];
      var map = null;
      var infowindow = null;
      var bound = new google.maps.LatLngBounds();

      var styles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#C60613"},{"visibility":"on"}]}];

      for (var i = 0; i < locations.length; i++) {
        var latLang = convertLatLong(locations[i]);
        bound.extend(new google.maps.LatLng(latLang.lat, latLang.long));
      }

      isDraggable = true;

      if(Modernizr.touch) isDraggable = false;

      var mapOptions = {
        center: bound.getCenter(),
        zoom: 10,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        scrollwheel: false,
        draggable: isDraggable,
        styles: styles,
      };

      map = new google.maps.Map(document.getElementById(mapId), mapOptions);
      var throttler = setTimeout(centerMap, 200);

      $(document).imagesLoaded(function() {
          setTimeout(centerMap, 500);
      });


      $(window).resize(function(event) {
        if(throttler) clearTimeout(throttler);
        throttler = setTimeout(centerMap, 500);
      });

      function centerMap() {
        map.setCenter(bound.getCenter());
      }

      google.maps.event.addListener(map, 'click', function() {
        infowindow.close();
      });
      function convertLatLong(location) {
        location = location.toString().replace('(', '').replace(')', '').replace(' ', '');
        location = location.split(',');
        var latLong = {lat: location[0], long: location[1]};
        return latLong;
      }
      function setMarkers(locations) {
        for (var i = 0; i < locations.length; i++) {
          var location = locations[i];
          var latLong = convertLatLong(location);
          var myLatLng = new google.maps.LatLng(latLong.lat, latLong.long);

          var image = {
            url: Drupal.settings.basePath + 'sites/all/themes/ibw_theme/img/marker.svg',
            size: new google.maps.Size(70, 54),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(35, 40),
          }

          if(iconSize === 'small') {
            image = {
              url: Drupal.settings.basePath + 'sites/all/themes/ibw_theme/img/marker.svg',
              size: new google.maps.Size(70, 54),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(35, 40),
            }
          }

          var marker = new google.maps.Marker({
            position: myLatLng,
            animation: google.maps.Animation.DROP,
            map: map,
            icon: image,
            title: location[0]
        });

        // Open google maps at coordinates on marker click
        google.maps.event.addListener(marker, "click", function () {
          window.open("https://maps.google.com/maps?daddr=" + convertLatLong(location).lat + "," + convertLatLong(location).long + "&hl=nl");
        });
        gmarkers.push(marker);
      }
    }
    setMarkers(locations);
    }
  })(jQuery);