(function ($) {

  Drupal.behaviors.scrollBehavior = {
    attach: function(context, settings){
      $('.l-header', context).once('scroll', function(){
        $(this).headroom({offset : 100});
      });
    }
  };

  Drupal.behaviors.placholderBehavior = {
    attach: function(context, settings){
      $('body', context).once('placholder', function(){
        $('input, textarea').placeholder();
      });
    }
  };



  Drupal.behaviors.slideBehavior = {
    attach: function(context, settings){
      $('.paragraphs-items-field-slides', context).once('slide', function(){
        var $slider = $(this);
        var $slides = $(this).find('.field--slides');
        var minHeight = 0;
        var resizeTimeout;

        $slider.fadeTo(0, 0);
          $(window).load(function() {
            setTimeout(init, 100);
          });

        function init() {
            $slider.fadeTo('300', 1);
            onResize();
            $(window).resize(throttleResize);
            addNavigation();
        };

        function throttleResize() {
          if(resizeTimeout) clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(onResize, 100);
        }

        function onResize() {
          calculateMinHeight();
          calculateImageAspectRatio();
        }

        function calculateMinHeight() {
          minHeight = 0;
          $slides.each(function() {
            var currentHeight = $(this).find('.field--slide-body').outerHeight();
            if(minHeight < currentHeight) minHeight = currentHeight;
          });
          $slider.css('min-height', minHeight);
        }

        function calculateImageAspectRatio() {
          var containerAR = $slider.outerWidth()/$slider.outerHeight();
          $slides.each(function() {
            var $img = $(this).find('img');
            var imgAR = $img.width()/$img.height();
            if(containerAR > imgAR) {
              $img.removeClass('fh');
            } else {
              $img.addClass('fh');
            }
          });
        }

      function addNavigation() {
        if($slides.length < 2) return false;
        var currentIndex = 0;
        var $pager = $('<div class="paragraphs-items-field-slides__pager"></div>');
        var $pages = $();

        $slides.each(function(index) {
          $pages = $pages.add($('<a href="#" class="paragraphs-items-field-slides__page">'+ (index + 1) +'</a>'))
        });

        $pages.eq(0).addClass('active');

        $slider.append($pager.append($pages));
        $pages.click(onPageClick);

        function onPageClick(e) {
          e.preventDefault();
          currentIndex = $(this).index();
          slide();
        }

        function slide() {
          $slides.fadeOut(300).eq(currentIndex).stop().fadeIn(300);
          $pages.removeClass('active').eq(currentIndex).addClass('active');
        }
      }

      });
    }
  };

  Drupal.behaviors.isotopeBehavior = {
    attach: function(context, settings){
      $('.field--image-gallery-wrp', context).once('isotope', function(){
        var $wrapper = $(this);
        var gutterWidth = $wrapper.find('.field--image-gallery').css('margin-bottom');
        gutterWidth = Math.floor(gutterWidth.split('px').join('') * 1);
        $wrapper.find('.field--image-gallery').css('margin-right', 0);
        $wrapper.imagesLoaded(init);
        function init() {
          $wrapper.masonry({itemSelector: '.field--image-gallery', gutter: gutterWidth});
        }
      });

      $('.view--news', context).once('isotope', function(){
        var $wrapper = $(this).find('.view-content');
        $wrapper.append('<div class="spacer"></div>');
        var $rows = $wrapper.find('.views-row').addClass('isotope');
        $wrapper.imagesLoaded(init);
        function init() {
          $wrapper.masonry({itemSelector: '.views-row', gutter: '.spacer'});
        }
      });

      $('.view-employees', context).once('isotope', function(){
        var $wrapper = $(this).find('.view-content');
        $wrapper.append('<div class="spacer"></div>');
        var $rows = $wrapper.find('.views-group').addClass('isotope');
        $rows.css('marginRight', 0);
        $wrapper.imagesLoaded(init);
        function init() {
          $wrapper.masonry({itemSelector: '.views-group', gutter: '.spacer'});
        }
      });
    }
  };

  Drupal.behaviors.touchTouchBehavior = {
    attach: function(context, settings) {
      $('.field--image-gallery-wrp', context).once('touchTouch', function(){
        var $images = $(this).find('.field--image-gallery').find('a');
        $images.touchTouch();
        $images.addClass('zoom-in');
      });
    }
  };

Drupal.behaviors.menuToggleBehavior = {
  attach: function(context, settings){
    $('.main-menu', context).once('menuToggle', function() {
      var $menu = $(this);
      var $menuToggle = $('<a href="#" class="menu-toggle">menu</a>');
      $menuToggle.insertBefore($menu);
      $menu.slideUp(0);

      $menuToggle.click(function() {
        $menu.slideToggle(300);
      });
    });
  }
};



})(jQuery);